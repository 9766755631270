import * as S from './header.styles'
import { FC, useState } from 'react'
import Menu from '../../components/Menu/menu'
import SimpleModal from '../../ui-kit/SimpleModal/SimpleModal'
import { useTranslation } from 'react-i18next'
import i18n from '../../i18n'
import { useLangSelect } from '../../hooks/LangSelect/LangSelect'
import { useSelector } from 'react-redux'
import { BOOK_NOW } from '../../config/config'
import HeaderContainer from '../../components/HeaderContainer/HeaderContainer'
import BookingButton from '../../components/BookingButton/BookingButton'
import { useLocation } from 'react-router-dom'

type HeaderProps = {
    hideBG?: boolean
}

const Header: FC<HeaderProps> = ({ hideBG = false }) => {
    const [langOptionOpen, setLangOptionOpen] = useState(false)
    const [currentLang, setCurrentLang] = useLangSelect(i18n.language.toUpperCase())
    const { t } = useTranslation()
    const { isMenuOpen } = useSelector((state: any) => state.menu)
    const loc = useLocation()

    function handlerBookBtn() {
        return () => {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
                event: 'header_btn_book_now_press',
                url: loc.pathname + '__' + loc.search,
            })
        }
    }

    return (
        <>
            <HeaderContainer hideBg={isMenuOpen || hideBG}>
                <S.HeaderWrapper>
                    <S.LogoWrapper>
                        <S.Logo to={''} />
                    </S.LogoWrapper>
                    <S.MenuWrapper>
                        <Menu />
                    </S.MenuWrapper>
                    <S.BookingBtnWrapper>
                        <S.LangMenuWrapper>
                            <SimpleModal
                                isOpen={langOptionOpen}
                                onClose={() => setLangOptionOpen(false)}
                            >
                                <S.LangMenu>
                                    <S.LangMenuOption className={currentLang === 'ENG' ? 'active' : ''}
                                                      onClick={() => setCurrentLang('ENG')}>ENG</S.LangMenuOption>
                                    <S.LangMenuOption className={currentLang === 'EST' ? 'active' : ''}
                                                      onClick={() => setCurrentLang('EST')}>EST</S.LangMenuOption>
                                    <S.LangMenuOption className={currentLang === 'RU' ? 'active' : ''}
                                                      onClick={() => setCurrentLang('RU')}>RU</S.LangMenuOption>
                                    <S.LangMenuOption className={currentLang === 'UKR' ? 'active' : ''}
                                                      onClick={() => setCurrentLang('UKR')}>UKR</S.LangMenuOption>
                                </S.LangMenu>
                            </SimpleModal>
                            <S.LangMenuBtn onClick={() => setLangOptionOpen(true)}>
                                {currentLang}
                            </S.LangMenuBtn>
                        </S.LangMenuWrapper>
                        <BookingButton url={BOOK_NOW} btnText={t('btn_header_bookNow')} onClick={handlerBookBtn} />
                    </S.BookingBtnWrapper>
                </S.HeaderWrapper>
            </HeaderContainer>
        </>
    )
}

export default Header
