export const BARBERSHOP_LAT: number = Number(process.env.REACT_APP_BARBERSHOP_LAT) || 0
export const BARBERSHOP_LNG: number = Number(process.env.REACT_APP_BARBERSHOP_LNG) || 0
export const BARBERSHOP_2_LAT: number = Number(process.env.REACT_APP_BARBERSHOP_2_LAT) || 0
export const BARBERSHOP_2_LNG: number = Number(process.env.REACT_APP_BARBERSHOP_2_LNG) || 0
export const MAP_API_KEY: string = process.env.REACT_APP_MAP_API_KEY || ''
export const BARBERSHOP_NAME: string = process.env.REACT_APP_DIRECTION_NAME || ''
export const BARBERSHOP_2_NAME: string = process.env.REACT_APP_B2_DIRECTION_NAME || 'Barbershop "Uncle Ve" Narva mnt'
export const BARBERSHOP_DIRECTION_LINK_WAZE: string = process.env.REACT_APP_DIRECTION_LINK_WAZE || ''
export const BARBERSHOP_DIRECTION_LINK_GOOGLE: string = process.env.REACT_APP_DIRECTION_LINK_GOOGLE || ''
export const BARBERSHOP_DIRECTION_B2_LINK_WAZE: string = process.env.REACT_APP_DIRECTION_B2_LINK_WAZE || ''
export const BARBERSHOP_DIRECTION_B2_LINK_GOOGLE: string = process.env.REACT_APP_DIRECTION_B2_LINK_GOOGLE || ''
export const BARBERSHOP_FACEBOOK_LINK: string = process.env.REACT_APP_FACEBOOK_LINK || ''
export const BARBERSHOP_INSTAGRAM_LINK: string = process.env.REACT_APP_INSTAGRAM_LINK || ''
export const BARBERSHOP_MAIL_ADDRESS: string = process.env.REACT_APP_MAIL_ADDRESS || ''
export const BARBERSHOP_PHONE: string = process.env.REACT_APP_PHONE || ''

export const BOOK_NOW: string = process.env.REACT_APP_BOOK_NOW || ''
export const SERVICES_1_MENS_HAIRCUT_BARBER_PRO: string = process.env.REACT_APP_SERVICES_1_MENS_HAIRCUT_BARBER_PRO || ''
export const SERVICES_1_MENS_HAIRCUT_BARBER: string = process.env.REACT_APP_SERVICES_1_MENS_HAIRCUT_BARBER || ''
export const SERVICES_1_MENS_HAIRCUT_BARBER_TRAINEE: string = process.env.REACT_APP_SERVICES_1_MENS_HAIRCUT_BARBER_TRAINEE || ''
export const SERVICES_1_BARBERSHOP_2_MENS_HAIRCUT: string = process.env.REACT_APP_SERVICES_1_BARBERSHOP_2_MENS_HAIRCUT || ''
export const SERVICES_MENS_HAIRCUT_PLUS_SERVICES_BEARD_CORRECTION: string = process.env.REACT_APP_SERVICES_MENS_HAIRCUT_PLUS_SERVICES_BEARD_CORRECTION || ''
export const SERVICES_2_MENS_HAIRCUT_PLUS_SERVICES_BEARD_CORRECTION_BARBER_PRO: string = process.env.REACT_APP_SERVICES_2_MENS_HAIRCUT_PLUS_SERVICES_BEARD_CORRECTION_BARBER_PRO || ''
export const SERVICES_2_MENS_HAIRCUT_PLUS_SERVICES_BEARD_CORRECTION_BARBER: string = process.env.REACT_APP_SERVICES_2_MENS_HAIRCUT_PLUS_SERVICES_BEARD_CORRECTION_BARBER || ''
export const SERVICES_2_MENS_HAIRCUT_PLUS_SERVICES_BEARD_CORRECTION_BARBER_TRAINEE: string = process.env.REACT_APP_SERVICES_2_MENS_HAIRCUT_PLUS_SERVICES_BEARD_CORRECTION_BARBER_TRAINEE || ''
export const SERVICES_2_BARBERSHOP_2_MENS_HAIRCUT_PLUS_SERVICES_BEARD_CORRECTION: string = process.env.REACT_APP_SERVICES_2_BARBERSHOP_2_MENS_HAIRCUT_PLUS_SERVICES_BEARD_CORRECTION || ''
export const SERVICES_3_BEARD_CORRECTION_BARBER_PRO: string = process.env.REACT_APP_SERVICES_3_BEARD_CORRECTION_BARBER_PRO || ''
export const SERVICES_3_BEARD_CORRECTION_BARBER: string = process.env.REACT_APP_SERVICES_3_BEARD_CORRECTION_BARBER || ''
export const SERVICES_3_BEARD_CORRECTION_BARBER_TRAINEE: string = process.env.REACT_APP_SERVICES_3_BEARD_CORRECTION_BARBER_TRAINEE || ''
export const SERVICES_3_BARBERSHOP_2_BEARD_CORRECTION: string = process.env.REACT_APP_SERVICES_3_BARBERSHOP_2_BEARD_CORRECTION || ''
export const SERVICES_4_BEARD_TINTING_BARBER_PRO: string = process.env.REACT_APP_SERVICES_4_BEARD_TINTING_BARBER_PRO || ''
export const SERVICES_4_BEARD_TINTING_BARBER: string = process.env.REACT_APP_SERVICES_4_BEARD_TINTING_BARBER || ''
export const SERVICES_4_BARBERSHOP_2_BEARD_TINTING: string = process.env.REACT_APP_SERVICES_4_BARBERSHOP_2_BEARD_TINTING || ''
export const SERVICES_5_CLIPPER_CUT_BARBER_PRO: string = process.env.REACT_APP_SERVICES_5_CLIPPER_CUT_BARBER_PRO || ''
export const SERVICES_5_CLIPPER_CUT_BARBER: string = process.env.REACT_APP_SERVICES_5_CLIPPER_CUT_BARBER || ''
export const SERVICES_5_CLIPPER_CUT_BARBER_TRAINEE: string = process.env.REACT_APP_SERVICES_5_CLIPPER_CUT_BARBER_TRAINEE || ''
export const SERVICES_5_BARBERSHOP_2_CLIPPER_CUT: string = process.env.REACT_APP_SERVICES_5_BARBERSHOP_2_CLIPPER_CUT || ''
export const SERVICES_6_CLIPPER_CUT_PLUS_SERVICES_BEARD_CORRECTION_BARBER_PRO: string = process.env.REACT_APP_SERVICES_6_CLIPPER_CUT_PLUS_SERVICES_BEARD_CORRECTION_BARBER_PRO || ''
export const SERVICES_6_CLIPPER_CUT_PLUS_SERVICES_BEARD_CORRECTION_BARBER_TRAINEE: string = process.env.REACT_APP_SERVICES_6_CLIPPER_CUT_PLUS_SERVICES_BEARD_CORRECTION_BARBER_TRAINEE || ''
export const SERVICES_6_CLIPPER_CUT_PLUS_SERVICES_BEARD_CORRECTION_BARBER: string = process.env.REACT_APP_SERVICES_6_CLIPPER_CUT_PLUS_SERVICES_BEARD_CORRECTION_BARBER || ''
export const SERVICES_6_BARBERSHOP_2_CLIPPER_CUT_PLUS_SERVICES_BEARD_CORRECTION: string = process.env.REACT_APP_SERVICES_6_BARBERSHOP_2_CLIPPER_CUT_PLUS_SERVICES_BEARD_CORRECTION || ''
export const SERVICES_7_FATHER_SON_BARBER_PRO: string = process.env.REACT_APP_SERVICES_7_FATHER_SON_BARBER_PRO || ''
export const SERVICES_7_FATHER_SON_BARBER_TRAINEE: string = process.env.REACT_APP_SERVICES_7_FATHER_SON_BARBER_TRAINEE || ''
export const SERVICES_7_FATHER_SON_BARBER: string = process.env.REACT_APP_SERVICES_7_FATHER_SON_BARBER || ''
export const SERVICES_7_BARBERSHOP_2_FATHER_SON: string = process.env.REACT_APP_SERVICES_7_BARBERSHOP_2_FATHER_SON || ''
export const SERVICES_8_CHILDRENS_HAIRCUT_BARBER_PRO: string = process.env.REACT_APP_SERVICES_8_CHILDRENS_HAIRCUT_BARBER_PRO || ''
export const SERVICES_8_CHILDRENS_HAIRCUT_BARBER_TRAINEE: string = process.env.REACT_APP_SERVICES_8_CHILDRENS_HAIRCUT_BARBER_TRAINEE || ''
export const SERVICES_8_CHILDRENS_HAIRCUT_BARBER: string = process.env.REACT_APP_SERVICES_8_CHILDRENS_HAIRCUT_BARBER || ''
export const SERVICES_8_BARBERSHOP_2_CHILDRENS_HAIRCUT: string = process.env.REACT_APP_SERVICES_8_BARBERSHOP_2_CHILDRENS_HAIRCUT || ''
export const ACHIFY_URL: string = process.env.REACT_APP_ACHIFY_URL || ''
