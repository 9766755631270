import styled from 'styled-components'

export const HeaderContainerOpacity = styled.header`
    display: flex;
    position: fixed;
    top: 0;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.backgroundHeaderOpacity};
    background-size: auto 100%;
    z-index: 10;
`
export const HeaderContainerOpaque = styled.header`
    display: flex;
    position: fixed;
    top: 0;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.backgroundHeader};
    background-size: auto 100%;
    z-index: 10;
`