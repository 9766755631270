import { FC, useCallback, useState } from 'react'
import { GoogleMap, InfoWindowF, MarkerF, useJsApiLoader } from '@react-google-maps/api'
import { LinkDirectionGoogle, LinkDirectionWaze, LinkWrapper, MarkerInfoWrapper } from './customGoogleMap.styles'
import {
    BARBERSHOP_2_LAT, BARBERSHOP_2_LNG,
    BARBERSHOP_2_NAME, BARBERSHOP_DIRECTION_B2_LINK_GOOGLE, BARBERSHOP_DIRECTION_B2_LINK_WAZE,
    BARBERSHOP_DIRECTION_LINK_GOOGLE,
    BARBERSHOP_DIRECTION_LINK_WAZE,
    BARBERSHOP_LAT,
    BARBERSHOP_LNG,
    BARBERSHOP_NAME,
    MAP_API_KEY,
} from '../../config/config'

interface IMapMarker {
    name: string,
    position: {
        lat: number,
        lng: number
    },
    width: number,
    height: number
}

const customMarker: IMapMarker = {
    name: BARBERSHOP_NAME,
    position: { lat: BARBERSHOP_LAT, lng: BARBERSHOP_LNG },
    width: 800,
    height: 800,
}

const customMarker2: IMapMarker = {
    name: BARBERSHOP_2_NAME,
    position: { lat: BARBERSHOP_2_LAT, lng: BARBERSHOP_2_LNG },
    // position: { lat: 59.438533142228295, lng: 24.770520754506393 },
    width: 800,
    height: 800,
}

let icon = {
    url: '/svg/icons/marker.svg',
}

const nightModeStyles = [
    {
        elementType: 'geometry',
        stylers: [{ color: '#212121' }],
    },
    {
        elementType: 'labels.text.stroke',
        stylers: [{ color: '#212121' }],
    },
    {
        elementType: 'labels.text.fill',
        stylers: [{ color: '#757575' }],
    },
    {
        featureType: 'administrative',
        elementType: 'geometry',
        stylers: [{ color: '#757575' }],
    },
    {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [{ color: '#181818' }],
    },
    {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#757575' }],
    },
    {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{ color: '#424242' }],
    },
    {
        featureType: 'road',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#757575' }],
    },
    {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [{ color: '#000000' }],
    },
    {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#757575' }],
    },
]
const apiKey: string = MAP_API_KEY

const CustomGoogleMap: FC = () => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: apiKey,
    })
    const [map, setMap] = useState(null)
    const center = {
        lat: 59.437510637139546,
        lng: 24.769626503621197,
    }
    const center_OLD = {
        lat: 59.436553,
        lng: 24.769528,
    }
    const onLoad = useCallback(function callback(map: any) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        // const bounds = new window.google.maps.LatLngBounds(center)
        // map.fitBounds(bounds)

        // @see Check real zoom
        // const zoom = map.getZoom();
        // console.log(zoom)
        // map.zoom(15)

        setMap(map)
    }, [])
    const onUnmount = useCallback(function callback(map: any) {
        setMap(null)
    }, [])

    if (!isLoaded) {
        return <div style={{ height: '526px', width: '100%', color: 'orange', fontSize: '72px' }}>Loading maps</div>
    }

    return (
        <>
            <div style={{ height: '526px', width: '100%' }}>
                {isLoaded ? (
                    <GoogleMap
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                        center={center}
                        zoom={16}
                        options={{ styles: nightModeStyles }}
                        mapContainerStyle={{ height: '526px', width: '100%' }}
                    >

                        <MarkerF
                            position={customMarker.position}
                            icon={icon}
                        >
                            <InfoWindowF position={customMarker.position}>
                                <MarkerInfoWrapper>
                                    <p>{customMarker.name}</p>
                                    <LinkWrapper>
                                        <LinkDirectionGoogle
                                            to={BARBERSHOP_DIRECTION_LINK_GOOGLE}>Google Maps</LinkDirectionGoogle>
                                        <LinkDirectionWaze
                                            to={BARBERSHOP_DIRECTION_LINK_WAZE}>Waze</LinkDirectionWaze>
                                    </LinkWrapper>
                                </MarkerInfoWrapper>
                            </InfoWindowF>
                        </MarkerF>

                        <MarkerF
                            position={customMarker2.position}
                            icon={icon}
                        >
                            <InfoWindowF position={customMarker2.position}>
                                <MarkerInfoWrapper>
                                    <p>{customMarker2.name}</p>
                                    <LinkWrapper>
                                        <LinkDirectionGoogle
                                            to={BARBERSHOP_DIRECTION_B2_LINK_GOOGLE}>Google Maps</LinkDirectionGoogle>
                                        <LinkDirectionWaze
                                            to={BARBERSHOP_DIRECTION_B2_LINK_WAZE}>Waze</LinkDirectionWaze>
                                    </LinkWrapper>
                                </MarkerInfoWrapper>
                            </InfoWindowF>
                        </MarkerF>

                    </GoogleMap>
                ) : null}
            </div>
        </>
    )
}

export default CustomGoogleMap