import { FC, ReactNode } from 'react'
import * as S from './headerContainer.styles'

type IHeaderContainerProps = {
    hideBg: boolean,
    children: any
}
const HeaderContainer: FC<IHeaderContainerProps> = ({hideBg, children}) => {
    return (
        <>
            {hideBg ? (<S.HeaderContainerOpaque className={'header-bg'}>
                {children}
            </S.HeaderContainerOpaque>):(<S.HeaderContainerOpacity className={'header-bg'}>
                {children}
            </S.HeaderContainerOpacity>)}
        </>
    )
}
export default HeaderContainer