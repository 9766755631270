import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
// import LanguageDetector from 'i18next-browser-languagedetector'
import translationEN from './assets/locales/eng/translation.json'
import translationEST from './assets/locales/est/translation.json'
import translationRU from './assets/locales/ru/translation.json'
import translationUKR from './assets/locales/ukr/translation.json'

const resources = {
    eng: {
        translation: translationEN,
    },
    est: {
        translation: translationEST,
    },
    ru: {
        translation: translationRU,
    },
    ukr: {
        translation: translationUKR,
    },
}

export const languages = Object.entries(resources).map(([lang]) => lang)

// @deprecated look into Lang.tsx
const removeLngPrefix = (pathname: string) => {
    for (let lang of languages) {
        if(pathname.startsWith(`/${lang}/`) || pathname === `/${lang}`) {
            return pathname.replace(`/${lang}`, '')
        }
    }
    return pathname
}
export const getLngFromUrl = (pathname: string) => {
    for (let lang of languages) {
        if (pathname.startsWith(`/${lang}/`) || pathname === `/${lang}`) {
            return lang;
        }
    }
    return null;
};

const lng = getLngFromUrl(window.location.pathname) || i18n.language;

i18n
    // detect user language //@TODO clear code before merge
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    // .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: false,
        // debug: true,
        fallbackLng: 'eng', // if there are no matches use this to try to solve
        lng,
        // lng: 'eng', // default lang
        // keySeparator: false, // we do not use keys in form messages.welcome @ we use Nested for SEO
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default,
        },
        // resources,
        react: { useSuspense: true },
        resources,
        // detection: {
        //     caches: ['cookie'],
        // },
    })

export default i18n