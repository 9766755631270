import { Dispatch, FC, lazy, SetStateAction, Suspense, useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import Layout from './components/Layout/Layout'
import Lang from './layouts/Lang/Lang'
import Home from './pages/Home/Home'
import NotFound from './pages/NotFound/NotFound'

export interface IAppProps {
    setTitle: Dispatch<SetStateAction<string>>
    setDescription: Dispatch<SetStateAction<string>>
    setKeywords: Dispatch<SetStateAction<string>>
}

const App: FC = () => {
    const [title, setTitle] = useState('title')
    const [description, setDescription] = useState('description')
    const [keywords, setKeywords] = useState('keywords')
    const appProps = {
        setTitle,
        setDescription,
        setKeywords
    }

    const Services = lazy(() => import('./pages/Services/Services'))
    const Gallery = lazy(() => import('./pages/Gallery/Gallery'))
    const Barbers = lazy(() => import('./pages/Barbers/Barbers'))
    const Shop = lazy(() => import('./pages/Shop/Shop'))
    const Contacts = lazy(() => import('./pages/Contacts/Contacts'))
    useEffect(() => {
        document.title = title
    }, [title])
    useEffect(() => {
        const metaDescription = document.querySelector('meta[name=\'description\']')
        if (metaDescription)
            metaDescription.setAttribute('content', description)
    }, [description])
    useEffect(() => {
        const metaDescription = document.querySelector('meta[name=\'keywords\']')
        if (metaDescription)
            metaDescription.setAttribute('content', keywords)
    }, [keywords])
    return (<>
        <Suspense fallback={<></>}>
            <Routes>
                <Route path="/" element={<Layout hideBg={false} />}>
                    <Route index element={<Home {...appProps} />} />
                </Route>
                <Route path={':lang'} element={<Lang />}>
                    <Route path="" element={<Layout hideBg={false} />}>
                        <Route index element={<Home {...appProps} />} />
                        <Route path="shop" element={<Shop {...appProps} />} />
                    </Route>
                    <Route path="" element={<Layout />}>
                        <Route path="services" element={<Services {...appProps} />} />
                        <Route path="gallery" element={<Gallery {...appProps} />} />
                        <Route path="contacts" element={<Contacts {...appProps} />} />
                        <Route path="barbers" element={<Barbers {...appProps} />} />
                        <Route path="*" element={<NotFound />} />
                    </Route>
                </Route>
            </Routes>
        </Suspense>
    </>)
}
export default App
