import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import i18n from './i18n'
import { store } from './store/store'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'
import { darkTheme } from './theme'
import GlobalStyle from './styles/global'
import { ThemeContext } from 'styled-components'

const root = createRoot(
    document.getElementById('root') as HTMLElement,
)

root.render(
    <StrictMode>
        <I18nextProvider i18n={i18n}>
            <ThemeContext.Provider value={darkTheme}>
                <GlobalStyle />
                <Provider store={store}>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </Provider>
            </ThemeContext.Provider>
        </I18nextProvider>
    </StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
