import { FC, useEffect } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Lang: FC = () => {
    const { i18n } = useTranslation()
    const { lang } = useParams()
    const navigate = useNavigate()
    let curPath = window.location.pathname
    useEffect(() => {
        if (lang === 'eng' &&
            (curPath === '/eng/' || curPath === '/eng')) {
            navigate('/', { replace: true })
            return
        }
        if (lang && i18n.resolvedLanguage !== lang) {
            navigate('/' + i18n.resolvedLanguage + curPath, { replace: true })
            return
        }
    }, [lang]) // route change

    useEffect(() => {
        let match = curPath.match(`^(?:\\/(?:eng|est|ukr|ru))\\/(\\w+)`)
        if (match) {
            curPath = '/' + match[1]
            navigate('/' + i18n.resolvedLanguage + curPath, { replace: true })
            return
        }
        if (curPath.match(`^(?:\\/(?:est|ukr|ru))`)) {
            navigate('/' + i18n.language, { replace: true })
            return
        }
    }, [i18n.language]) // trigger switch Lang in i18n.ts
    return <Outlet />
}
export default Lang