import { FC, useEffect } from 'react'
import { IAppProps } from '../../App'
import { useTranslation } from 'react-i18next'
import { BgImage, PageWrapper } from '../Page/page.styles'
import * as S from './home.styles'
import { BARBERSHOP_FACEBOOK_LINK, BARBERSHOP_INSTAGRAM_LINK } from '../../config/config'
import Metrics from '../../components/Metrics/Metrics'
import AboutUs from '../../components/AboutUs/AboutUs'
import Location from '../../components/Location/Location'
import GalleryHome from '../../components/GalleryHome/GalleryHome'
import LogoLine from '../../components/LogoLine/LogoLine'
import { useNavigate } from 'react-router-dom'
import i18n from '../../i18n'

const Home: FC<IAppProps> = (props) => {
    const { t } = useTranslation()
    useEffect(() => {
        props.setTitle(t(`seo.title`, { lng: 'eng' }))
        props.setDescription(t(`seo.description`, { lng: 'eng' }))
        props.setKeywords(t(`seo.keywords`, { lng: 'eng' }))
    }, [])


    const navigate = useNavigate()
    let curPath = window.location.pathname
    useEffect(() => {
        if (curPath === '/' && i18n.resolvedLanguage != 'eng') {
            navigate('/' + i18n.resolvedLanguage, { replace: true })
        }
        if (i18n.resolvedLanguage !== 'eng') {
            props.setTitle(t(`seo.title`))
            props.setDescription(t(`seo.description`))
            props.setKeywords(t(`seo.keywords`))
        }
    }, [i18n.resolvedLanguage]) // here we work ONLY with Main page curPath === '/'
    return (
        <>
            <BgImage>
                <S.HomePageWrapper>
                    <S.SocialWrapper>
                        <S.SocialLinkInstagram to={BARBERSHOP_INSTAGRAM_LINK} />
                        <S.SocialLinkFacebook to={BARBERSHOP_FACEBOOK_LINK} />
                    </S.SocialWrapper>
                    <S.HomeTitleWrapper>
                        <S.HomeTitle>{t('title_page_home')}</S.HomeTitle>
                        <S.HomeSubtitle>{t('home_subtitle')}</S.HomeSubtitle>
                    </S.HomeTitleWrapper>
                </S.HomePageWrapper>
                <LogoLine />
            </BgImage>
            <PageWrapper>
                <AboutUs />
                <Metrics />
            </PageWrapper>
            <GalleryHome />
            <Location />
        </>
    )
}

export default Home
