import { FC, useEffect } from 'react'
import Footer from '../../layouts/Footer/footer'
import { Outlet, useNavigate } from 'react-router-dom'

import Container from '../../layouts/container/container'
import Header from '../../layouts/Header/header'
import i18n from '../../i18n'

type LayoutProps = {
    hideBg?: boolean
}

const Layout: FC<LayoutProps> = ({ hideBg = true }) => {
    const navigate = useNavigate()
    let curPath = window.location.pathname
    useEffect(() => {
        if (curPath === '/' && i18n.language != 'eng') {
            navigate('/' + i18n.language, { replace: true })
            return
        }
    }, [i18n.language]) // here we work ONLY with Main page curPath === '/'
    return (
        <>
            {hideBg ? (
                <>
                    <Header hideBG={hideBg} />
                    <Container>
                        <Outlet />
                    </Container>
                    <Footer />
                </>
            ) : (
                <>
                    <Header />
                    <Outlet />
                    <Footer />
                </>
            )}
        </>)
}
export default Layout
