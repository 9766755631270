import { FC, LinkHTMLAttributes } from 'react'
import { BookingBtn } from '../../ui-kit/btn/bookBtn.styles'

interface IBookingBtnProps extends LinkHTMLAttributes<HTMLLinkElement>{
    url: string;
    btnText: string;
}

const BookingButton: FC<IBookingBtnProps> = ({ url, btnText }) => {
    return (
        <BookingBtn to={url}>
            <span>{btnText}</span>
            <p>{btnText}</p>
        </BookingBtn>
    )
}

export default BookingButton
